import React, { useState, useEffect, useRef} from 'react'
import { FormWrapper } from './FormWrapper.tsx'
import axios from 'axios'
import style from './UploadChats.module.css'
import { useNavigate } from 'react-router-dom'

type UploadData = {
    file: File | null,
    chatType: string;
}

type UploadFormProps = UploadData & {
    updateFormData: (fields: Partial<UploadData>) => void,
}

export function UploadForm({ file, chatType, updateFormData }: UploadFormProps) {
    const [msg, setMsg] = useState<string | null>(null)
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
    }, [file])


    function handleUpload() {
        if (!file) {
            setMsg("No file selected")
            return
        }

        if (file.size > 1048576 * 100) {
            setMsg("File size exceeds 100MB")
            return
        }

        if (file.type !== "application/zip") {
            setMsg("Only zip files are allowed")
            return
        }

        const fd = new FormData()
        fd.append('file', file)
        fd.append('chatType', chatType);
        setMsg("Uploading...")

        axios.post('https://kai-tech.org/uploadchats', fd, {
            onUploadProgress: (progressEvent) => {
                console.log('Upload progress: ' + Math.round(progressEvent.loaded / (progressEvent.total || 1) * 100))
            },
            headers: {
                "Custom-Header": "value",
            }
        })
        .then(res => {
            setMsg("Uploaded successfully")
            setTimeout(() => {
                setMsg(null);
                navigate('/profile');
            }, 3000)
        })
        .catch(err => {
            setMsg("Upload failed")
            console.error(err)
        })
    }

    return (
        <FormWrapper title="Upload your chat history">

            <input ref={fileInputRef}  onChange={(e) => updateFormData({ file: e.target.files ? e.target.files[0] : null })} type="file" id="filee" name="filee" />
            <p>Select the chat history file that you exported from the messenger of your choice</p>
            <label htmlFor="filee" className={style.uploadButton}>Choose a chat file</label>
            {file && <p>Selected file: {file.name}</p>}
            <p style={{paddingTop: "30px"}}> Select the messenger you exported chat history from</p>
            <div style={{paddingTop: "0px"}}>
                <label>
                    <input className={style.inputField}
                        type="radio" 
                        value="whatsapp" 
                        checked={chatType === "whatsapp"} 
                        onChange={(e) => updateFormData({ chatType: e.target.value })} 
                    />
                    WhatsApp
                </label>
                <label>
                    <input className={style.inputField}
                        type="radio" 
                        value="telegram" 
                        checked={chatType === "telegram"} 
                        onChange={(e) => updateFormData({ chatType: e.target.value })} 
                    />
                    Telegram
                </label>
            </div>

            {msg && <p>{msg}</p>}


        </FormWrapper>
    )
}
