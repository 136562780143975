import React from 'react'
import TalkingCircleComponentContainer from '../../components/TalkingCircle/TalkingCircleComponentContainer';



const TalkingCircle = () => {

    return (
        <TalkingCircleComponentContainer />
    )
}


export default TalkingCircle;