import React from 'react';
import style from './ProfileComponent.module.css';
import { NavLink } from 'react-router-dom';
import personaImage from "../../assets/images/persona1.png";


const ProfileComponent = ({ 
    personas,
    loading,
    error,
    onPersonaClick,
    isModalOpen,
    selectedPersona,
    onConfirmActivation,
    onCancelActivation,
    isPhoneNumberInputVisible,
    phoneNumber,
    onPhoneNumberChange,
    onPhoneNumberSubmit,
    onActivate
 }) => {

    return (
        <div className={style.bodyElement}>
            <div className={style.text}>
                <div className={style.bold}>Welcome to StayWithMe!</div>
                <div>The app is being rapidly developed, so expect frequent updates with new features and improvements.</div>
                <div> Contact us: ‎ 
                <a href="mailto:staywithme.contactus@gmail.com">staywithme.contactus@gmail.com</a> </div>

                <div className={style.talkWeb}> Talk via phone</div>
                <div>To try default persona, <b>activate</b> it in the section below and then call: ‎ 
                <a href="tel:+15209993998">+1 (520)-999-3998</a></div>
            </div>
            <div>
                <NavLink to="/upload-chats">
                    <button className={style.buttonUploadChats}>Create new persona</button>
                </NavLink>
            </div>
            
            <div>
                <p>Click on persona to activate it:</p>
            </div>    
            <div className={style.personasList}>
                {loading && <p>Loading personas...</p>}
                {error && <p>Error: {error}</p>}
                {!loading && !error && personas.length > 0 ? (
                    <div className={style.personaButtonsContainer}>
                    {personas.map((persona) => (
                        <button 
                            key={persona.id} 
                            className={style.personaButton + (persona.isActive ? ` ${style.activeGlow}` : '')}
                            onClick={() => onPersonaClick(persona)}
                        >
                            <img 
                                src={personaImage}
                                alt={persona.name} 
                                className={style.personaImage} 
                            />
                            <span className={style.personaName}>{persona.name}</span>
                        </button>
                    ))}
                </div>
                ) : (
                    <p>No personas available.</p>
                )}
            </div>    
            <div>
                {isModalOpen && (
                    <div className={style.modalOverlay}>
                        <div className={style.modalWindow}>
                            <p className={style.modalTitle}>Activate Persona</p>
                            <p className={style.modalText}>Do you want to activate {selectedPersona?.name}?</p>
                            {selectedPersona?.id?.startsWith('default_') && (
                                <p className={style.modalNote}>
                                    The default persona is limited in features like long-term memory and other capabilities. 
                                    For a better experience, we encourage you to create your own persona.
                                </p>
                            )}
                            <button className={`${style.modalButton} ${style.modalButtonConfirm}`} onClick={onConfirmActivation}>
                                Yes
                            </button>
                            <button className={`${style.modalButton} ${style.modalButtonCancel}`} onClick={onCancelActivation}>
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
                {isPhoneNumberInputVisible && (
                    <div className={style.phoneInputContainer}>
                        <input
                            type="number"
                            required
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                            placeholder='123-456-7890'
                            className={style.phoneInput} // Add this line for styling
                        />
                        <button className={style.phoneInputSubmit} onClick={() => {onPhoneNumberSubmit(); onActivate(); }}>
                            Activate
                        </button>
                    </div>)}
            </div>


            <div className={style.talkWeb}>
                Talk on web
                <div>
                    <NavLink to="/talk">
                        <button className={style.buttonTalk}>Start Talking</button>
                    </NavLink>
                </div>
            </div>
        </div>      
)};

export default ProfileComponent;