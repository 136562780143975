import React, { useEffect, useRef } from "react";
import style from './TalkingCircle.module.css';
import { motion } from 'framer-motion';

const TalkingCircleComponent = ({ selectedOption, handleSelectChange, options, isPulsing, isThinking, handleTogglePulse, audioStream, transcript, displayText, personas }) => {
    const audioRef = useRef(null); // Create a ref to store the audio element

    useEffect(() => {
        if (audioStream) {
            // Create a new audio object from the stream
            audioRef.current = new Audio(URL.createObjectURL(audioStream));
            audioRef.current.play();

            // Clean up: stop audio when the stream changes or component unmounts
            return () => {
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.src = ""; // Clear the audio source
                }
            };
        }
    }, [audioStream]);
    return (
        <div className={style.personSelectionRow}>
            <div className={style.selectPerson}>
                <label htmlFor="options">Choose an option: </label>
                <select id="options" value={personas.name} onChange={handleSelectChange}>
                    {personas.map((persona) => (
                        <option key={persona.id} value={persona.name}>
                            {persona.name} 
                        </option>
                    ))}
                </select>
                <div className={style.language_display}>
                    {selectedOption.language_display}
                </div>
            </div>

            <div className={style.circleWrapper}>
                <div className={style.displayTextListetning}>
                    {displayText}
                </div>
                <svg height='50%' width='50%' viewBox="0 0 450 450" className={style.svgcircle}>
                    <motion.circle
                        r="200" 
                        cx="225" 
                        cy="225" 
                        fill="white"
                        height="450" 
                        width="450" 
                        animate={
                            isPulsing 
                            ? { scale: [1, 1.05, 1], opacity: [1, 0.7, 1] } 
                            : isThinking 
                            ? { y: [15, -15, 15], opacity: [1, 1], rotate: [0, 360]} 
                            : { scale: [1], opacity: [1, 1], y: 0 }
                        }
                        transition={isPulsing || isThinking ? { repeat: Infinity, duration: 0.7, ease: "easeInOut" } : {}}
                        onClick={() => handleTogglePulse()} 
                        className={style.circleSVG}
                    />
                </svg>
                <div>
                <div><h2>Transcript</h2></div>
                <div style={{ whiteSpace: 'pre-wrap', padding: '-300px', borderRadius: '5px', maxHeight: '300px', overflowY: 'auto' }}>
                    {transcript || ''}
                </div>
            </div>
            </div>
        </div>
    );
}

export default TalkingCircleComponent;
