import React, { useEffect, useState } from "react";
import ProfileComponent from "./ProfileComponent";
import { useSelector, useDispatch } from 'react-redux';
import { fetchPersonas } from '../../redux/reducers/personasReducer';
import { activatePersona } from '../../redux/reducers/setActivePersonaReducer';
import { useNavigate } from 'react-router-dom';


const ProfileComponentContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // Redux state: List of personas, loading, and error status
    const { list: personas, loading, error } = useSelector(state => state.personas);

    // Local state: For modal and phone number input logic
    const [selectedPersona, setSelectedPersona] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPhoneNumberInputVisible, setPhoneNumberInputVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    // Fetch personas when the component mounts
    useEffect(() => {
        dispatch(fetchPersonas());
    }, [dispatch]);

    // Handle persona button click
    const handlePersonaClick = (persona) => {
        setSelectedPersona(persona);
        setIsModalOpen(true);
    };

    // Confirm activation and show phone number input
    const handleConfirmActivation = () => {
        setPhoneNumberInputVisible(true);
        setIsModalOpen(false);
    };

    // Cancel activation and reset state
    const handleCancelActivation = () => {
        setSelectedPersona(null);
        setIsModalOpen(false);
    };

    // Handle phone number change
    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    // Handle phone number submission and dispatch activation action
    const handlePhoneNumberSubmit = () => {
        if (phoneNumber.trim()) {
            dispatch(activatePersona(selectedPersona.id, phoneNumber));
            setPhoneNumber('');
            setPhoneNumberInputVisible(false);
        }
    };

    const onActivate = async () => {
        await activatePersona(selectedPersona.id, phoneNumber);
        navigate(0); // Refresh the page by navigating to the same page
    };

    return (
        <ProfileComponent
            personas={personas}
            loading={loading}
            error={error}
            onPersonaClick={handlePersonaClick}
            isModalOpen={isModalOpen}
            selectedPersona={selectedPersona}
            onConfirmActivation={handleConfirmActivation}
            onCancelActivation={handleCancelActivation}
            isPhoneNumberInputVisible={isPhoneNumberInputVisible}
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
            onPhoneNumberSubmit={handlePhoneNumberSubmit}
            onActivate={onActivate}
        />
    );
};

export default ProfileComponentContainer;
